<template>
    <div class="dict">
        <div class="form-header-btn">
            <div class="header-title">手动同步</div>
            <div>
            </div>
        </div>
        <el-form ref="form"  label-width="undefined">
			<el-form-item>
			    <el-button type="primary" @click="flushRedisDictUsingGET" v-loading="loadingDict" :disabled="loadingDict">刷新字典</el-button>
			</el-form-item>
            <el-form-item>
                <el-button type="primary" @click="syncDeptUsingGET" v-loading="loadingDept" :disabled="loadingDept">组织同步</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="syncAllPostUsingGET" v-loading="loadingPost" :disabled="loadingPost">岗位同步</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="syncChildAndStaff" v-loading="loadingStaff" :disabled="loadingStaff">投保信息同步</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="clearPostOrder" v-loading="loadingClearOrder" :disabled="loadingClearOrder">清空级联岗位顺序</el-button>
            </el-form-item>
             <el-form-item>
                <el-button type="primary" @click="updateSuperviseGET" v-loading="loadingUpdateSupervise" :disabled="loadingUpdateSupervise">同步督导任务接收人和审批人</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    data(){
        return{
			loadingDict:false,
            loadingDept:false,
            loadingPost:false,
            loadingStaff:false,
            loadingClearOrder:false,
            loadingUpdateSupervise:false
        }
    },
    // created(){},
    methods:{

        updateSuperviseGET(){
			this.loadingUpdateSupervise = true
			this.$api.updateSuperviseGET().then(res=>{
			    this.loadingUpdateSupervise = false
			    if(res.data.code == 0){
			        this.$message.success('刷新成功')
			    }else{
			        this.$message.error(res.data.msg)
			    }
			}).catch(()=>{
			    this.loadingDict = false
			    this.$message.error('刷新失败')
			})
		},

		flushRedisDictUsingGET(){
			this.loadingDict = true
			this.$api.flushRedisDictUsingGET().then(res=>{
			    this.loadingDict = false
			    if(res.data.code == 0){
			        this.$message.success('刷新成功')
			    }else{
			        this.$message.error(res.data.msg)
			    }
			}).catch(()=>{
			    this.loadingDict = false
			    this.$message.error('刷新失败')
			})
		},
        syncDeptUsingGET(){
            this.loadingDept = true
            this.$api.syncDeptUsingGET().then(res=>{
                this.loadingDept = false
                console.log(res.data)
                if(res.data.code == 0){
                    this.$message.success('更新成功')
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(()=>{
                this.loadingDept = false
                this.$message.error('更新失败')
            })
        },
        syncAllPostUsingGET(){
            this.loadingPost = true
            this.$api.syncAllPostUsingGET().then(res=>{
                this.loadingPost = false
                if(res.data.code == 0){
                    this.$message.success('更新成功')
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(()=>{
                this.loadingPost = false
                this.$message.error('更新失败')
            })
        },
        syncChildAndStaff(){
            this.loadingStaff = true
            this.$api.syncChildAndStaff().then(res=>{
                this.loadingStaff = false
                if(res.data.code == 0){
                    this.$message.success('更新成功')
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(()=>{
                this.loadingStaff = false
                this.$message.error('更新失败')
            })
        },
        clearPostOrder(){
            this.loadingClearOrder = true
            this.$api.clearPostOrder().then(res=>{
                this.loadingClearOrder = false
                if(res.data.code == 0){
                    this.$message.success('更新成功')
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(()=>{
                this.loadingClearOrder = false
                this.$message.error('更新失败')
            })
        },
    }
}
</script>
<style lang="scss" scoped>
// @import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 64px);
  width: 100%;
  padding: 34px 30px 30px 30px;
  .tableList {
    height: calc(100% - 100px);
  }
  .form-header-btn{
    margin-bottom: 24px;
  }
}
/deep/.el-button--primary{
    width: inherit;
}
</style>